import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { IHubspotModalProps } from './@types/IHubspotModalProps.props'
import { IModalProps } from './@types/IModalProps.props'
import { HUBSPOT_MEETING_URL } from 'shared/constants'

const Modal = styled.div<IModalProps>(({
  show, top, left,
}) => {
  return css`
  width: 750px;
  min-height: 700px;
  background: white;
  position: fixed;
  top: ${top || '5vh'};
  left: ${left || '25vw'};
  z-index: ${show ? '3000' : '-3000'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  display: flex;
  padding-top: 1rem;

  @media (max-width: 850px){
    width:700px;
    left: 5vw;
  }
button{
  position: relative;
  left: 45%;
  background: transparent;
  border: none;
  span{
      display: inline-block;
      width: 32px;
      height: 32px;
      opacity: 0.3;

      :hover{
          opacity: 1;
      }

      ::before, ::after {
          position: absolute;
          
          content: ' ';
          height: 33px;
          width: 2px;
          background-color: #00194A;
      }

      ::before{
          transform: rotate(45deg);
      }

      ::after{
          transform: rotate(-45deg);
      }
  }
}
  iframe {
    width: 100%;
    flex: 1;
  }
`
})

export function HubspotMeetingModal({
  show, closeMeeting, top, left,
}: IHubspotModalProps): JSX.Element {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    script.async = true
    script.type = 'text/javascript'
    document.body.appendChild(script)
    script.onload = () => {
      setIsScriptLoaded(true)
    }
  }, [])

  return (
    <>
      {isScriptLoaded && (
        <Modal
          left={left}
          show={show}
          top={top}
        >
          <button onClick={closeMeeting}>
            <span
              aria-hidden='true' />
          </button>
          <iframe
            src={HUBSPOT_MEETING_URL}
            style={{ border: 'none' }}
            title='hubspot'
          />
        </Modal>
      )}
    </>
  )
}

export default HubspotMeetingModal

import React, { useEffect, useState } from "react";
import "./PriceList.scss";
import ProductLayout from "containers/productLayout/ProductLayout";
import Seo from "utils/seo/Seo";
import { navigate, PageProps } from "gatsby";
import { ProductPageProps } from "features/product-page/product-homepage/productHomeService";
import Credentials from "features/homepage/credentials/Credentials";
import Breadcrumbs from "components/shared-components/breadcrumbs/Breadcrumbs";
import { useLocale } from "locale/useLocale";
import { Showcase } from "./components/Showcase";
import { PriceListBanner } from "./components/PriceListBanner";
import { FAQSection } from "./components/FAQSection";
import { useProductPricelistData } from "../productPricelistService";
import { FreeTrialBox } from "./components/FreeTrialBox";
import { Packages } from "./components/Packages";
import {useMediaQuery} from "utils/hooks/useMediaQuery";
import { HubspotMeetingModal } from "ui/HubspotMeetingModal/HubspotMeetingModal";
import { useDispatch, useSelector } from "react-redux";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { RootState } from "utils/store/store";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../../../linkResolver.js');

function PriceList (props: PageProps) {
  const name = (props.pageContext as ProductPageProps).productName;
  const data = useProductPricelistData(props.pageContext as ProductPageProps);
  const locale = useLocale("Pricelist");
  const dispatch = useDispatch();
  const {mobile} = useMediaQuery()
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('product_pricelist');
  const { language } = useSelector((state: RootState) => state.i18n);

  const [showMeeting, setShowMeeting] = useState(false);

  // handlers for hubspot meeting modal
  const handleShowMeeting = () => {
    if (!showMeeting && !mobile) {
      setShowMeeting(true);
    }
    if (!showMeeting && mobile){
      navigate(`/${language}/contact/`);
    }
  };

  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };
  
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  return (
    <>
      <ProductLayout handleHubspot={handleShowMeeting} productName={name}>
        <Seo
          title={data?.seo_title?.text}
          description={data?.seo_description?.text}
        />

        { displayPopup && <GenericPopup /> }

        <div className="pricelist-container">
          <div className="pricelist-container__main">
            <div className="pricelist-container__main--inner">
              <Breadcrumbs
                first="dataplace.ai"
                second={locale.pricelist}
                firstLink=""
              />
              <Showcase
                title={data?.title?.raw || []}
                label={data?.label?.raw || []}
                button={data?.button?.raw || []}
              />
              {language === 'pl' && (
                <>
                  <FreeTrialBox
                    button={data?.free_trial_button?.raw || []}
                    description={data?.free_trial_text?.raw || []}
                  />
                  <Packages
                    packages={data?.package_box}
                    handleShowMeeting={handleShowMeeting}
                  />
                </>
              )}
            </div>
          </div>
          {language === 'pl' && (
            <>
              <PriceListBanner
                title={data?.banner_title?.raw || []}
                description={data?.banner_description?.raw || []}
                button={data?.banner_button?.raw || []}
                image={{
                  url: data?.banner_img?.url ?? "",
                  alt: data?.banner_img?.alt ?? "",
                }}
              />
              <FAQSection title={data?.faq_title?.raw} faq={data?.faq} />
            </>
          )}
        </div>

        {(language === 'pl' || language === 'en-pl') && (
          <div className="pricelist-credentials">
            <Credentials />
          </div>
        )}

        {showMeeting && (
          <HubspotMeetingModal
            closeMeeting={handleCloseMeeting}
            left="25vw"
            show={showMeeting}
            top="8vh"
          />
        )}
      </ProductLayout>
    </>
  );
};
export default withPrismicPreview(PriceList, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);

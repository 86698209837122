import { useLocale } from "locale/useLocale";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled, { css } from "styled-components";
import { InputMaybe, PrismicProductHomeDataBodyBenefitsSlice, Scalars } from "types/declarations";
import BtnArrowRight from "assets/arrowRight.svg";
import { useMediaQuery } from "utils/hooks/useMediaQuery";
import { colors, productColors } from "shared/constants";
import SharedButton from 'ui/SharedButton/SharedButton';


interface FlexVersionProps {
  displayFlex?: boolean
}

interface SectionWrapperProps {
  backgroundColor?: InputMaybe<Scalars['String']>
}

const SectionWrapper = styled.section<SectionWrapperProps>(({ backgroundColor }) => css`
  background-color: ${backgroundColor ? backgroundColor : colors.background};
`)

const BenefitsSliceContainer = styled.div<FlexVersionProps>(({ displayFlex }) => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: column;
    padding: ${displayFlex ? '8px 84px 105px 84px' : '78px 84px 105px 84px'}; 
    max-width: 1440px;
    margin: 0 auto;

    ${tablet && css`padding: 50px 25px;`}
    ${mobile && css`padding: 28px 15px;`}
  `
});

const Title = styled.h1(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    margin: 0;
    font-size: 40px;
    line-height: 52px;
    color: ${colors.darkBlue};

    ${tablet && css`
      font-size: 37px;
    `}

    ${mobile && css`
      font-size: 30px;
      line-height: 40px;
    `}
  `
});

const Section = styled.section<FlexVersionProps>(({ displayFlex }) => {
  const { mobile } = useMediaQuery()

  return css`
    display: ${displayFlex ? 'flex' : 'grid'};
    grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    gap: 20px;
    justify-content: space-between;
    margin: ${mobile ? '30px 0' : '40px 0'};
    ${mobile && css`flex-direction: column;`}
  `
});

const Item = styled.div<FlexVersionProps>(({ displayFlex }) => {
  const { desktop } = useMediaQuery()

  return css`
    background-color: white;
    border-radius: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${displayFlex
    ? css`
      padding: 60px;
    `
    : css`
      padding: 26px;
      ${(desktop) && 'max-width: 292px'};
    `}
  `
});

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  flex-shrink: 0;
`;

const ItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ItemTitle = styled.h3`
  margin: 15px 0 10px 0;
  text-align: left;
  font-size: 20px;
  line-height: 25px;
  color: ${colors.darkBlue};
`;

const ItemContent = styled.p`
  margin: 0;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${colors.darkBlue};
`;

const SHARED_BUTTON_PROPS = {
  $bgColor: productColors.violet.dark,
  $color: colors.white,
}
interface BusinessBenefitsProps {
  slice: PrismicProductHomeDataBodyBenefitsSlice
  buttonOnClick?(): void
}

const BusinessBenefits: React.FC<BusinessBenefitsProps> = ({ slice, buttonOnClick }) => {
  const freeTrialBannerLocale = useLocale('FreeTrialBanner')
  const productListBoxLocale = useLocale('ProductListBox')
  const { mobile } = useMediaQuery()
  const flexVersion = !!slice?.primary?.flexible_tiles_width

  return (
    <SectionWrapper backgroundColor={slice?.primary?.section_background_color}>
      <BenefitsSliceContainer  displayFlex={flexVersion}>
        <Title>{RichText.asText(slice?.primary?.business_benefits_section_title?.richText)}</Title>
        <Section displayFlex={flexVersion}>
          {slice?.items?.map((tile, index) => (
            <Item key={tile?.tile_image?.url || index} displayFlex={flexVersion}>
              <Image src={tile?.tile_image?.url || ''} alt={tile?.tile_image?.alt || ''} />
              <ItemContentContainer>
                <ItemTitle>{RichText.asText(tile?.tile_title?.richText)}</ItemTitle>
                <ItemContent>{RichText.asText(tile?.tile_content?.richText)}</ItemContent>
              </ItemContentContainer>
            </Item>
          ))}
        </Section>
        {slice.primary?.business_benefits_section_link?.url?.length
          ? (
            mobile
              ? (
                <SharedButton
                  buttonProps={{
                    $width: '100%',
                    ...SHARED_BUTTON_PROPS,
                  }}
                  onClick={buttonOnClick}
                >
                  {productListBoxLocale.letsTalk}
                  <BtnArrowRight />
                </SharedButton>
              )
              : (
                <SharedButton
                  buttonProps={{
                    $width: 'fit-content',
                    ...SHARED_BUTTON_PROPS,
                  }}
                  redirectsTo={slice.primary?.business_benefits_section_link?.url ?? ''}
                >
                  {freeTrialBannerLocale.ftbTitle}
                  <BtnArrowRight />
                </SharedButton>
              )
            )
          : null}
      </BenefitsSliceContainer>
    </SectionWrapper>
  );
}

export default BusinessBenefits;

import { graphql, useStaticQuery } from "gatsby";
import { useSelector } from "react-redux";
import {
  PrismicProductHome,
  PrismicProductPricelist,
  PrismicProductPricelistDataType,
  PrismicProductPricelistEdge,
} from "types/declarations";
import { RootState } from "utils/store/store";
import { ProductPageProps } from "features/product-page/product-homepage/productHomeService";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useProductPricelistData = ({
  productName,
}: ProductPageProps): PrismicProductPricelistDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicProductPricelist {
        edges {
          node {
            lang
            tags
            _previewable
            data {
              seo_title {
                text
              }
              seo_description {
                text
              }
              button {
                raw
              }
              label {
                raw
              }
              title {
                raw
              }
              banner_button {
                raw
              }
              banner_description {
                raw
              }
              banner_img {
                alt
                url
              }
              banner_title {
                raw
              }
              faq {
                answer {
                  raw
                }
                question {
                  raw
                }
              }
              faq_title {
                raw
              }
              free_trial_button {
                raw
              }
              free_trial_text {
                raw
              }
              package_box {
                available_analyzes {
                  raw
                }
                available_analyzes_label {
                  raw
                }
                available_analyzes_modal {
                  raw
                }
                box_button {
                  raw
                }
                box_description {
                  raw
                }
                box_modal_button {
                  raw
                }
                box_title {
                  raw
                }
                included_analyzes {
                  raw
                }
                is_popular
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicProductPricelist.edges
    .map((edge: PrismicProductPricelistEdge) => edge.node)
    .filter((node: PrismicProductPricelist) => node.lang === language)
    .filter((node: PrismicProductHome) => node.tags.includes(productName))
    .map((node: PrismicProductPricelist) => node.data)[0];
};

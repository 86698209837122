import { graphql, useStaticQuery } from "gatsby";
import {
  PrismicContact,
  PrismicContactDataType,
  PrismicContactEdge,
} from "types/declarations";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { IPostContactForm } from './contactModel'
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

export const useContactData = (): PrismicContactDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicContact {
        edges {
          node {
            lang
            _previewable
            data {
              email {
                raw
                text
              }
              first_address {
                raw
              }
              second_address {
                raw
              }
              header {
                raw
              }
              image {
                alt
                url
              }
              subheader {
                raw
              }
              phone_number {
                raw
                text
              }
              form_header {
                raw
              }
              form_name {
                raw
              }
              form_product {
                raw
              }
              form_product_options {
                raw
              }
              form_mail {
                raw
              }
              form_phone {
                raw
              }
              form_industry {
                raw
              }
              form_industry_options {
                raw
              }
              form_departament {
                raw
              }
              form_departament_options {
                raw
              }
              form_submit {
                raw
              }
              seo_title {
                text
              }
              seo_description {
                text
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicContact.edges
    .map((edge: PrismicContactEdge) => edge.node)
    .filter((node: PrismicContact) => node.lang === language)
    .map((node: PrismicContact) => node.data)[0];
};

export const postContactForm = (props: IPostContactForm, lang: string, setModalDisplay: React.Dispatch<React.SetStateAction<boolean>>): void => {
 const getUrlByLanguage = (lang: string) =>{
   switch (lang) {
     case 'pl':
       return 'https://api.hsforms.com/submissions/v3/integration/submit/20363741/2f1cd568-cbad-4743-a0ff-1e48c0798ef4'
     case 'en-pl':
       return 'https://api.hsforms.com/submissions/v3/integration/submit/20363741/d2cd0aa4-fc6b-4a55-972b-1389022f3ac5'
     default:
       return 'https://api.hsforms.com/submissions/v3/integration/submit/20363741/2f1cd568-cbad-4743-a0ff-1e48c0798ef4'
      }
 }
 const xhr = new XMLHttpRequest();
   const url = getUrlByLanguage(lang)
   // Example request JSON:
   const data = {
     "fields": [
       {
         "name": "email",
         "value": props.mail
       },
       {
         "name": "company",
         "value": props.company
       },
       {
         "name": "client_department",
         "value": props.department
       },
       {
         "name": "subscribe_for_newsletter",
         "value": props.newsletter
       }
     ],
    
     "legalConsentOptions": { // Include this object when GDPR options are enabled
       "consent": {
         "consentToProcess": true,
         "text": "I agree to allow Example Company to store and process my personal data.",
         "communications": [
           {
             "value": true,
             "subscriptionTypeId": 999,
             "text": "I agree to receive marketing communications from Example Company."
           }
         ]
       }
     }
   }

   const final_data = JSON.stringify(data);

   xhr.open('POST', url);
   // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
   xhr.setRequestHeader('Content-Type', 'application/json');
   xhr.onreadystatechange = function() {
     if(xhr.readyState == 4 && xhr.status === 200) {
       setModalDisplay(true)
     }
   }

   // Sends the request 
   xhr.send(final_data)
};
import BusinessBenefits from 'features/product-page/product-homepage/business-benefits-section/BusinessBenefits'
import AccessData from 'features/product-page/product-homepage/product-access-data/AccessData'
import Capabilities from 'features/product-page/product-homepage/product-capabilities/Capabilities'
import Hero from 'features/product-page/product-homepage/product-hero/Hero'
import PredictionModels from 'features/product-page/product-homepage/product-prediction-models/PredictionModels'
import React from 'react'
import { slicesIds } from 'shared/constants'
import {
  PrismicProductHomeDataBodyAccessDataSlice,
  PrismicProductHomeDataBodyHeroCardSlice,
  PrismicProductHomeDataBodyCapabilitiesSlice,
  PrismicProductHomeDataBodyPredictionModelsSlice,
  PrismicProductHomeDataBodyBenefitsSlice,
  PrismicProductHomeSlicesType,
  Maybe,
} from 'types/declarations'

interface RenderSliceProps {
  slice: Maybe<PrismicProductHomeSlicesType>
}

export function renderSlice({ slice }: RenderSliceProps) {
  switch (slice?.slice_type) {
    case slicesIds.heroCardSlice:
      return <Hero slice={slice as PrismicProductHomeDataBodyHeroCardSlice} key={slice.id} />

    case slicesIds.benefitsSlice:
      return <BusinessBenefits key={slice.id} slice={slice as PrismicProductHomeDataBodyBenefitsSlice} />

    case slicesIds.predictionModelsSlice:
      return <PredictionModels slice={slice as PrismicProductHomeDataBodyPredictionModelsSlice} key={slice.id} />

    case slicesIds.capabilitiesSlice:
      return <Capabilities key={slice.id} slice={slice as PrismicProductHomeDataBodyCapabilitiesSlice} />

    case slicesIds.accessDataSlice:
      return <AccessData key={slice.id} slice={slice as PrismicProductHomeDataBodyAccessDataSlice} />

    default:
      return <div key={slice?.id} />
  }
}

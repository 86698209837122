import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { PageProps, navigate } from "gatsby";
import { getStoryPostDetails } from "../successStoriesSlice";
import "./PostDetails.scss";
import SuccessStoriesLayout from "containers/succesStories-layout/SuccessStoriesLayout";
import CtaBanner from "features/success-stories/items/banners/CtaBanner";
import ProductTrialBanner from "../items/banners/ProductTrialBanner";
import Infobar from "./items/post-infobar/Infobar";
import SimilarPosts from "./items/similar-posts/SimilarPosts";
import Breadcrumbs from "features/success-stories/items/breadcrumbs/Breadcrumbs";
import { SuccessStory } from "../successStoriesModel";
import { RichText } from "prismic-reactjs";
import {
  Maybe,
  PrismicSuccessStoryDataBodySlicesType,
  PrismicSuccessStoryDataBodyText,
  PrismicSuccessStoryDataBodyTextPrimary,
  PrismicSuccessStoryDataBodyCredentialsBanner,
  PrismicSuccessStoryDataBodyEffects,
  PrismicSuccessStoryDataBodySimmilarSuccessStory,
  PrismicSuccessStoryDataBodySidebarBox,
  PrismicSuccessStoryDataBodyEffectsPrimary,
  PrismicSuccessStoryDataBodyImagePrimary,
  PrismicSuccessStoryDataBodyImage,
} from "types/declarations";
import PostBox from "./items/post-box/PostBox";
import CredentialsBanner from "./items/post-banners/CredentialsBanner";
import Seo from "utils/seo/Seo";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../../linkResolver.js');

const PostDetails = (props: PageProps): JSX.Element => {
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.i18n);
  const link = (props.pageContext as Partial<SuccessStory>).link;
  const { successStory } = useSelector(
    (state: RootState) => state.successStories
  );
  const displayPopup = checkForPopup()
    ?.page?.text?.split(";")
    ?.filter(p => p !== "")
    ?.includes("success_story_post");

  useEffect(() => {
    dispatch(getStoryPostDetails({ link }));
  }, [link, language]);

  const handleShowMeeting = () => {
    navigate(`/${language}/contact/`);
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, []);
  
  return (
    <SuccessStoriesLayout>
      <Seo title={successStory?.seo_title} description={successStory?.seo_description} />

      {displayPopup && <GenericPopup />}

      <div className="ss-post-container">
        <div className="ss-post-header">
          <div className="ss-post-header__inner">
            <Breadcrumbs
              firstName={language === "pl" ? "Klienci" : "Clients"}
              secondName="Historie klientów"
              secondLink="success-stories"
              thirdName={successStory?.title}
            />
            <div className="ss-post-header__labels">
              {successStory?.labels?.text?.trim().split(";").filter(point => point !== '').map((label, index) => {
                return (
                  <div
                    className="success-story-post__labels--label"
                    key={index}>
                    {label}
                  </div>
                );
              })}
            </div>
            <h3 className="ss-post__title">{successStory?.title}</h3>
          </div>
        </div>
        <div className="ss-post-body">
          <div className="ss-post-body__left">
            <div className="ss-post-body__left--client-logo">
              <img
                src={successStory?.client_logo?.url ?? ""}
                alt={successStory?.client_logo?.alt ?? ""}
              />
            </div>
            <Infobar
              tags={successStory?.tags}
              client={successStory?.client_name}
              industry={successStory?.client_industry}
            />
            <div className="ss-post__left--content">
              {successStory?.content?.map(
                (
                  slice: Maybe<PrismicSuccessStoryDataBodySlicesType>,
                  index: number
                ) => {
                  if (slice?.slice_type === "text") {
                    const textSlice = slice as PrismicSuccessStoryDataBodyText;
                    const text = textSlice?.primary as PrismicSuccessStoryDataBodyTextPrimary;
                    return <RichText key={index} render={text?.text?.raw} />;
                  }
                  if (slice?.slice_type === "credentials_banner") {
                    const bannerSlice = slice as PrismicSuccessStoryDataBodyCredentialsBanner;
                    if (bannerSlice) {
                      return (
                        <div className="product-banner__container" key={index}>
                          <CredentialsBanner banner={bannerSlice?.primary} />
                        </div>
                      );
                    }
                  }
                  if (slice?.slice_type === "image") {
                    const imageSlice = slice as PrismicSuccessStoryDataBodyImage;
                    const image = imageSlice?.primary as PrismicSuccessStoryDataBodyImagePrimary;
                    return (
                      <div className='img-post' key={index}>
                        <img
                          src={image.image?.url ?? ""}
                          alt={image.image?.alt ?? ""}
                        />
                      </div>
                    );
                  }
                  if (slice?.slice_type === "effects") {
                    const effectsSlice = slice as PrismicSuccessStoryDataBodyEffects;
                    const effects = effectsSlice?.primary as PrismicSuccessStoryDataBodyEffectsPrimary;
                    if (effects) {
                      return (
                        <div className="ss-post-effects" key={index}>
                          <div className="ss-post-effects__inner">
                            <RichText
                              key={index}
                              render={effects?.effects_text?.raw || []}
                            />
                          </div>
                        </div>
                      );
                    }
                  }
                  return null;
                }
              )}
            </div>
          </div>

          {successStory?.content?.map(
            (
              slice: Maybe<PrismicSuccessStoryDataBodySlicesType>,
              index: number
            ) => {
              if (slice?.slice_type === "sidebar_box") {
                const box = slice as PrismicSuccessStoryDataBodySidebarBox;
                if (box) {
                  return <PostBox key={index} boxContent={box?.primary} />;
                } else {
                  return null;
                }
              }
              return null;
            }
          )}
        </div>

        {successStory?.content?.map(
          (slice: Maybe<PrismicSuccessStoryDataBodySlicesType>, index: number) => {
            if (slice?.slice_type === "simmilar_success_story") {
              const similar = slice as PrismicSuccessStoryDataBodySimmilarSuccessStory;
              if (similar) {
                return <SimilarPosts items={similar?.items} key={index} />;
              } else {
                return null;
              }
            }
            return null;
          }
        )}
        <ProductTrialBanner />
        <CtaBanner />
      </div>
    </SuccessStoriesLayout>
  );
};
export default withPrismicPreview(PostDetails, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);

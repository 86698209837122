export const colors = {
  main: '#001D62',
  background: '#FBF7F4',
  black: '#15181F',
  white: '#FFFFFF',
  gray: {
    light: '#EFEAEB',
    light2: '#E0E0E0',
    dark: '#828282'
  },
  overlay: {
    transparentDark: '#000000d9'
  },
  darkBlue: '#002163',
  lightBlue: '#234596',
  red: '#ff0000'
} as const

export const productColors = {
  location: '#362FB7',
  navigator: '#CC3C00',
  marketing: '#9D0584',
  network: '#0075A7',
  data: '#6830B2',
  violet: {
    dark: '#331A6D',
    light: '#331a6d26',
  },
  dark: '#111111',
  lightViolet: '#ADAAE3',
  white: '#FFF'
} as const

export const slicesIds = {
  imageSlice: 'image_slice',
  benefitsSlice: 'benefits_slice',
  capabilitiesSlice: 'capabilities_slice',
  accessDataSlice: 'access_data_slice',
  predictionModelsSlice: 'prediction_models_slice',
  talkSlice: 'talk_slice',
  heroCardSlice: 'hero_card_slice',
  brandCarouselSlice: 'brand_carousel_slice',
  calendarSlice: 'calendar_slice',
  successStories: 'success_stories',
  reviewsSlice: 'reviews_slice',
  pricingFormSlice: 'pricing_form_slice',
  faqSlice: 'faq_slice'
} as const

export const OVERLAY_STYLE = {
  backgroundColor: colors.overlay.transparentDark,
} as const

export const HUBSPOT_MEETING_URL = 'https://meetings.hubspot.com/dataplace/meet-with-dataplaceai?embed=true'

export const WEBAPP3_URL = 'https://app.dataplace.ai'
export const AUTH_URL = `${WEBAPP3_URL}/auth`
export const SIGN_UP_URL = `${AUTH_URL}/sign-up`
export const SIGN_IN_URL = `${AUTH_URL}/sign-in`

export const languages = {
  pl: 'pl',
  en: 'en-pl',
  de: 'de-de',
  fr: 'fr-fr'
} as const

export const placemeUrls = {
  placeme: 'placeme',
  pricing: 'pricing'
}

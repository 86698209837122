import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { IHubspotFormModal } from './@types/IHubspotFormModal'
import { IHubspotFormModalProps } from './@types/IHubspotFormModalProps.props'

const Modal = styled.div<IHubspotFormModal>(({
  show, top, left,
}) => {
  return css`
  width: 550px;
  background: white;
  position: fixed;
  top: ${top || '10vh'};
  left: ${left || '25vw'};
  z-index: ${show ? '2000' : '-2000'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  display: flex;
  padding-top: 1rem;
  @media (max-width: 850px){
    width:700px;
    left: 5vw;
  }

  div{
    position: relative;
    bottom: 20px;
    max-height: 700px;
  }

  button{
  position: relative;
  left: 45%;
  background: transparent;
  border: none;
  span{
      display: inline-block;
      width: 32px;
      height: 32px;
      opacity: 0.3;

      :hover{
          opacity: 1;
      }

      ::before, ::after {
          position: absolute;
          
          content: ' ';
          height: 33px;
          width: 2px;
          background-color: #00194A;
      }

      ::before{
          transform: rotate(45deg);
      }

      ::after{
          transform: rotate(-45deg);
      }
  }
}
`
})

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hbspt: any;
  }
}

export function HubspotMeetingModal({
  show, closeMeeting, top, left,
}:IHubspotFormModalProps) : JSX.Element {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  const loadForm = () => {
    if('hbspt' in window) {
        window.hbspt.forms.create({
            portalId: "20363741",
            formId: "c61c7789-c163-408b-817e-2c577b9d5475",
            target: "#form-container"
        });
        setIsScriptLoaded(true)
      } else {
        setTimeout(loadForm, 500)
      }
}

  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/shell.js'
    script.async = true
    script.type = 'text/javascript'
    document.body.appendChild(script)
    script.onload = () => {
      loadForm()
    }
   
  }, [])

  return (
    <>
      {isScriptLoaded && (
        <Modal
          left={left}
          show={show}
          top={top}
        >
        <button onClick={closeMeeting}>
            <span
            aria-hidden='true'/>
          </button>
          <div id="form-container"/>
        </Modal>
      )}

    </>
  )
}

export default HubspotMeetingModal

import React from "react";
import styled, { css } from "styled-components";
import { IButtonProps } from "./@types/IButtonProps.props";
import BtnArrowRight from "assets/arrowbtn.svg";

const StyledButton = styled.button<IButtonProps>(
  ({ minWidth, reverse, padding, font_weight, height, border, borderRadius, backgroundCol, maxWidth }) => {
    return css`
      color: ${reverse ? "#001D62" : "white"};
      background: ${backgroundCol || (reverse? "white" : "#001D62")};
      border-radius: ${borderRadius || "5px"};
      outline: none;
      border: ${border || "1px solid #001D62"};
      min-width: ${minWidth || "120px"};
      max-width: ${maxWidth || "none"};
      padding: ${padding || "8px 12px"};
      font-size: 17px;
      font-weight: ${font_weight || "400"};
      height: ${height || "60px"};
      cursor: pointer;

      transition: all 0.2s ease-in-out;
      svg {
        width: 12px;
        height: 12px;
        margin-left: 16px;
        path {
          stroke: ${reverse ? "#001D62" : "#fff"};
        }
      }

      :disabled {
        background: #acacac;
        box-shadow: 0 0 5px rgba(128, 128, 128, 0.5);
        border-color: #acacac;
      }

      :hover {
        color: ${reverse ? "white" : "#001D62"};
        background: ${reverse ? "#001D62" : "white"};
        border: ${reverse ? "1px solid white" : "1px solid #001D62"};
        box-shadow: none;
        cursor: pointer;

        svg {
          path {
            stroke: ${reverse ? "#fff" : "#001D62"};
          }
        }
      }

      :disabled:hover {
        color: white;
        background: #acacac;
        border: 1px solid #acacac;
        box-shadow: 0 0 5px rgba(128, 128, 128, 0.5);
        cursor: default;
      }

      a {
        padding: 0;
      }
    `;
  }
);

const Button: React.FC<IButtonProps> = ({
  children,
  onClick,
  minWidth,
  as,
  to,
  reverse,
  arrowRight,
  ...restProps
}) => {
  return (
    <>
      {as === "navLink" && to ? (
        <a href={to}>
          <StyledButton
            reverse={reverse}
            {...restProps}
            minWidth={minWidth}
            onClick={onClick}>
            {children}
            {arrowRight && <BtnArrowRight />}
          </StyledButton>
        </a>
      ) : (
        <StyledButton
          reverse={reverse}
          {...restProps}
          minWidth={minWidth}
          onClick={onClick}>
          {children}
          {arrowRight && <BtnArrowRight />}
        </StyledButton>
      )}
    </>
  );
};
export default Button;

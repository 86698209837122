import {
  Maybe,
  PrismicBlogBodySlicesType,
  PrismicBlogPostBodySlicesType,
  PrismicImageType,
  PrismicStructuredTextType,
} from "types/declarations";

export interface Tag {
  value: string;
  valueEn?: string
}

export interface SearchFilter extends Tag {
  id: string;
  selected: boolean;
  icon?: string;
  iconSelected?: string;
  link?: string;
}

export interface AdditionalSearchFilter extends Tag {
  id: string;
  selected: boolean;
}

export interface BlogPost {
  content?: Maybe<Array<Maybe<PrismicBlogPostBodySlicesType>>>;
  id: string;
  title: string;
  link: string;
  description: string;
  image: Maybe<PrismicImageType>;
  tags: Array<Tag>;
  additionalTags: Array<AdditionalSearchFilter>;
  isFeatured?: boolean;
  post_category?:Maybe<PrismicStructuredTextType>;
  post_labels?:Maybe<PrismicStructuredTextType>;
  table_of_contents?: Maybe<PrismicStructuredTextType>;
  seo_title: string;
  seo_description: string;
  order: string;
}

export interface BlogFormBanner {
  content?: Maybe<Array<Maybe<PrismicBlogPostBodySlicesType>>>;
  button?: Maybe<PrismicStructuredTextType>;
  header?: Maybe<PrismicStructuredTextType>;
  image?: Maybe<PrismicImageType>;
  industry?: Maybe<PrismicStructuredTextType>;
  industry_options?: Maybe<PrismicStructuredTextType>;
  mail?: Maybe<PrismicStructuredTextType>;
  position?: Maybe<PrismicStructuredTextType>;
}

export interface BlogReportBanner {
  content?: Maybe<Array<Maybe<PrismicBlogPostBodySlicesType>>>;
  image?: Maybe<PrismicImageType>;
  link?: Maybe<PrismicStructuredTextType>;
  position?: Maybe<PrismicStructuredTextType>;
  title?: Maybe<PrismicStructuredTextType>;
  button?: Maybe<PrismicStructuredTextType>;
}

export interface BlogBannerDTO {
  body: Maybe<Array<Maybe<PrismicBlogBodySlicesType>>>;
}

export type BlogBanner = BlogReportBanner | BlogFormBanner;
export type HomeItem = BlogPost | BlogBanner;

export interface BlogPostDetails extends BlogPost {
  content: Maybe<Array<Maybe<PrismicBlogPostBodySlicesType>>>;
}

export interface GetHome {
  allBlogPosts: Array<BlogPost>;
  filters: Array<SearchFilter>;
  pagination: Pagination;
}

export interface GetSearchResults extends GetHome {
  query: string;
}

export interface ToggleFilterById {
  id: string;
  filter: SearchFilter;
}

export interface Pagination {
  page: number;
  pageSize: number;
  numberOfItems: number;
}

export interface GetBlogPostDetails {
  allBlogPosts?: Array<BlogPostDetails>;
  link?: string;
}

export interface IPostSubscriptionForm {
  email: string;
  industry: string;
}

export interface IPostSubscriptionFormBlogPost {
  email: string;
}

export function isBlogPost(arg: HomeItem): arg is BlogPost {
  return (
    (arg as BlogPost).title !== undefined && (arg as BlogPost).tags !== undefined
  );
}

export function isReportBanner(arg: BlogReportBanner): arg is BlogReportBanner {
  return arg.link !== undefined;
}

export function isFormBanner(arg: BlogFormBanner): arg is BlogFormBanner {
  return arg.industry !== undefined;
}


import React from 'react'
import Seo from 'utils/seo/Seo'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Layout from 'containers/layout/Layout'
import { colors, slicesIds } from 'shared/constants'
import SuccessStories from 'features/product-page/product-homepage/product-success-stories/SuccessStories'
import { PrismicProductHomeDataBodyBrandCarouselSlice, PrismicProductHomeDataBodySuccessStoriesSlice } from 'types/declarations'
import BrandCarousel from 'features/product-page/product-homepage/product-brand-carousel/BrandCarousel'
import PricingSlice from 'components/slices/pricing-form-slice/PricingSlice'
import { PrismicProductHomeDataBodyPricingFormSlice } from 'components/slices/pricing-form-slice/PricingFormTypes'
import styled, { css } from 'styled-components'
import FaqSlice from 'components/slices/faq-slice/FaqSlice'
import { PrismicProductHomeDataBodyFaqSlice } from 'components/slices/faq-slice/FaqTypes'
import { usePricingData } from './hooks/usePricingData'

const linkResolver = require('../../../../linkResolver.js')

const BrandCarouselWrapper = styled.div(() => css`
  max-width: 1440px;
  margin: 0 auto;
`)

const Pricing = (): JSX.Element => {
  const pricing = usePricingData()

  return (
    <>
      <Seo title={pricing?.seo_title?.text} description={pricing?.seo_description?.text}/>
      <Layout backgroundColor={colors.background}>
      {pricing?.body?.map((slice, index) => {
        switch (slice?.slice_type) {
          case slicesIds.successStories:
            return <SuccessStories key={slice.id} slice={slice as PrismicProductHomeDataBodySuccessStoriesSlice} />

          case slicesIds.brandCarouselSlice:
            return (
              <BrandCarouselWrapper>
                <BrandCarousel 
                  key={slice.id} 
                  slice={slice as PrismicProductHomeDataBodyBrandCarouselSlice} 
                  padding='3.5rem 0'
                  backgroundColor={colors.background}
                />
              </BrandCarouselWrapper>
            )

          case slicesIds.pricingFormSlice:
            return <PricingSlice key={slice.id} slice={slice as PrismicProductHomeDataBodyPricingFormSlice} />
        
          case slicesIds.faqSlice:
            return <FaqSlice key={slice.id} slice={slice as PrismicProductHomeDataBodyFaqSlice} />

          default:
            return <div key={index} />
        }
      })}
      </Layout>
    </>
  )
}

export default withPrismicPreview(Pricing, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
])

import React, { useEffect } from "react";
import "./PostDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { PageProps } from "gatsby";
import { BlogPostDetails } from "features/blog/blogModel";
import { RootState } from "utils/store/store";
import { getRecruitmentPost } from "../careerSlice";
import CareerLayout from "containers/career-layout/CareerLayout";
import About from "../items/about/About";
// import Newsletter from "../items/newsletter/Newsletter";
import Header from "./header/Header";
import {
  Maybe,
  PrismicRecruitmentPostDataBodyHighlight,
  PrismicRecruitmentPostDataBodyHighlightPrimary,
  PrismicRecruitmentPostDataBodySlicesType,
  PrismicRecruitmentPostDataBodyText,
  PrismicRecruitmentPostDataBodyTextPrimary,
} from "types/declarations";
import { RichText } from "prismic-reactjs";
// import Cat from "assets/cat.svg";
// import Dog from "assets/dog.svg";
import Button from "ui/button/Button";
import { useLocale } from "locale/useLocale";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../../linkResolver.js');

function PostDetails (props: PageProps) {
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.i18n);
  const locale = useLocale("Career");
  const link = (props.pageContext as Partial<BlogPostDetails>).link;
  const { recruitmentPost } = useSelector(
    (state: RootState) => state.recruitment
  );
  const mailLink = 'mailto:career@dataplace.ai?subject=Rerutacja';
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('career_post');

  useEffect(() => {
    dispatch(getRecruitmentPost({ link }));
  }, [link, language]);

  return (
    <CareerLayout>
      { displayPopup && <GenericPopup /> }

      <Header post={recruitmentPost} />
      <div className="post">
        <div className="post-highlight">
          <div className="post-highlight__inner">
          {recruitmentPost?.content?.map(
            (
              slice: Maybe<PrismicRecruitmentPostDataBodySlicesType>,
              index: number
            ) => {
              if (slice?.slice_type === "highlight") {
                const textSlice = slice as PrismicRecruitmentPostDataBodyHighlight;
                const text = textSlice?.primary as PrismicRecruitmentPostDataBodyHighlightPrimary;
                return <RichText render={text?.text?.raw || []} key={index} />;
              }
              return null;
            }
          )}
          </div>
        </div>
        <div className="post__inner">
          <div className="post__inner--left">
            <div className="post__inner--left---content">
              {recruitmentPost?.content?.map(
                (
                  slice: Maybe<PrismicRecruitmentPostDataBodySlicesType>,
                  index: number
                ) => {
                  if (slice?.slice_type === "text") {
                    const textSlice = slice as PrismicRecruitmentPostDataBodyText;
                    const text = textSlice?.primary as PrismicRecruitmentPostDataBodyTextPrimary;
                    return <RichText render={text?.text?.raw} key={index} />;
                  }
                  return null;
                }
              )}
            </div>
            {/* <div className="line-bg">
              <div className="line"></div>
            </div> */}
            {/* <div className="post__inner--bottom">
              <h5 className="post__inner--bottom---title">
                {locale.animals}
              </h5>
              <div className="post__inner--bottom---icons">
                <Cat />
                <Dog />
              </div>
              <p className="post__inner--bottom---description">
                {recruitmentPost?.animals_label}
              </p>
            </div> */}
          </div>
          <div className="post__inner--right">
              <div className="post__inner--right---box">
                <h1 className="box--title">
                  {recruitmentPost?.title}
                </h1>
                <div className="box--content">
                  {recruitmentPost?.post_icons?.map((icon, i) => <div key={i}>
                        <img
                          src={icon?.icon?.url ?? ""}
                          alt={icon?.icon?.alt ?? ""}
                        />
                        <p>{RichText.asText(icon?.label?.raw)}</p>
                      </div>
                   )}
                </div>
                <a className="contact-button__text" href={mailLink}>
                  <Button
                    className="box--button"
                    as="button"
                    minWidth={180}
                    arrowRight
                    filled>
                    {locale.apply}
                  </Button>
                </a>
              </div>
          </div>
        </div>
      </div>
      <About />
      {/* <Newsletter /> */}
    </CareerLayout>
  );
};
export default withPrismicPreview(PostDetails, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);

import ProductLayout from "containers/productLayout/ProductLayout";
import Seo from "utils/seo/Seo";
import BusinessBenefits from "features/product-page/product-homepage/business-benefits-section/BusinessBenefits";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { ProductPageProps, useProductHomeData } from "features/product-page/product-homepage/productHomeService";
import HubspotMeetingModal from 'ui/HubspotMeetingModal/HubspotMeetingModal'
import { navigate, PageProps } from 'gatsby';
import {useMediaQuery} from "utils/hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { slicesIds } from "shared/constants";
import ProductAnalyseBusiness from "./product-analyse-business/ProductAnalyseBusiness";
import AccessData from "./product-access-data/AccessData";
import Capabilities from "./product-capabilities/Capabilities";
import PredictionModels from './product-prediction-models/PredictionModels';
import { 
  PrismicProductHomeDataBodyAccessDataSlice,
  PrismicProductHomeDataBodyBenefitsSlice,
  PrismicProductHomeDataBodyCapabilitiesSlice,
  PrismicProductHomeDataBodyPredictionModelsSlice,
  PrismicProductHomeDataBodySuccessStoriesSlice,
  PrismicProductHomeDataBodyTalkSlice,
  PrismicProductHomeDataBodyHeroCardSlice,
  PrismicProductHomeDataBodyReviewsSlice,
  PrismicProductHomeDataBodyCalendarSlice,
  PrismicProductHomeDataBodyBrandCarouselSlice,
} from 'types/declarations';
import TalkSection from "./product-talk-section/TalkSection";
import SuccessStories from "./product-success-stories/SuccessStories";
import Hero from './product-hero/Hero';
import Reviews from './product-reviews/Reviews';
import Calendar from './product-calendar/Calendar';
import BrandCarousel from "./product-brand-carousel/BrandCarousel";

const linkResolver = require('../../../../../linkResolver.js');

function ProductPlaceme(props: PageProps) {
  const name = (props.pageContext as ProductPageProps).productName;
  const { language } = useSelector((state: RootState) => state.i18n);
  const productData = useProductHomeData(props.pageContext as ProductPageProps);
  const dispatch = useDispatch();
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('product_home');
  const predictionModelsSectionRef = useRef<HTMLElement>(null)
  // TODO: pass this ref to calendar section
  const calendarSectionRef = useRef<HTMLElement>(null)
  
  const [showMeeting, setShowMeeting] = useState(false);

  const {mobile} = useMediaQuery()

  const handleShowMeeting = () => {
    if (!showMeeting && !mobile) {
      setShowMeeting(true);
    } else {
      navigate(`/${language}/contact/`)
    }
  };
  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  const scrollToPredictionModals = useCallback(() => {
    predictionModelsSectionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [])

  const scrollToCalendar = useCallback(() => {
    calendarSectionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }, [])

  return (
    <ProductLayout handleHubspot={handleShowMeeting} productName={name}>
      <Seo title={productData?.seo_title?.text} description={productData?.seo_description?.text} />
      {displayPopup ? <GenericPopup /> : null}
      {showMeeting && <HubspotMeetingModal
        closeMeeting={handleCloseMeeting}
        left='25vw'
        show={showMeeting}
        top='8vh'
      />}
      {productData?.body?.map((slice, index) => {
        switch (slice?.slice_type) {
          case slicesIds.heroCardSlice:
            return (
              <Hero
                slice={slice as PrismicProductHomeDataBodyHeroCardSlice}
                key={slice.id}
                onLeftButtonClick={handleShowMeeting}
              />
            )
          
          case slicesIds.benefitsSlice:
            return (
            <BusinessBenefits
              key={slice.id}
              slice={slice as PrismicProductHomeDataBodyBenefitsSlice}
              buttonOnClick={handleShowMeeting}
            />
          );

          case slicesIds.imageSlice:
            return (
              <ProductAnalyseBusiness
                key={slice.id}
                slicePrimary={slice.primary}
                leftButtonOnClick={scrollToPredictionModals}
                rightButtonOnClick={handleShowMeeting}
              />
            );

          case slicesIds.accessDataSlice:
            return <AccessData key={slice.id} slice={slice as PrismicProductHomeDataBodyAccessDataSlice} />;

          case slicesIds.capabilitiesSlice:
            return <Capabilities key={slice.id} slice={slice as PrismicProductHomeDataBodyCapabilitiesSlice} />;

          case slicesIds.predictionModelsSlice:
            return (
              <PredictionModels
                slice={slice as PrismicProductHomeDataBodyPredictionModelsSlice}
                key={slice.id}
                ref={predictionModelsSectionRef}
              />
            )

          case slicesIds.talkSlice:
            return (
              <TalkSection 
                key={slice.id} 
                slice={slice as PrismicProductHomeDataBodyTalkSlice} 
                handleOpenCalendar={handleShowMeeting} 
                handleScrollToCalendar={scrollToCalendar}
              />
            )

          case slicesIds.successStories:
            return <SuccessStories key={slice.id} slice={slice as PrismicProductHomeDataBodySuccessStoriesSlice} />;


          case slicesIds.reviewsSlice:
            return <Reviews key={slice.id} slice={slice as PrismicProductHomeDataBodyReviewsSlice} />

          case slicesIds.calendarSlice:
            return <Calendar key={slice.id} ref={calendarSectionRef} slice={slice as PrismicProductHomeDataBodyCalendarSlice} />
          
          case slicesIds.brandCarouselSlice:
            return <BrandCarousel key={slice.id} slice={slice as PrismicProductHomeDataBodyBrandCarouselSlice} />;

          default:
            return <div key={index} />;
        }
      })}
    </ProductLayout>
  );
};

export default withPrismicPreview(ProductPlaceme, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);

import * as React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import store from './src/components/utils/store/store';
import { Provider } from 'react-redux';
import { componentResolverMap } from './withPrismicPreviewSettings';

const linkResolver = require('./linkResolver');

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: 'carcavelos',
          linkResolver: linkResolver.linkResolver,
          componentResolver: componentResolverFromMap(componentResolverMap),
        },
      ]}>
      <Provider store={store}>{element}</Provider>
    </PrismicPreviewProvider>
  );
};

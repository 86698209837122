import * as React from 'react';
import MobileHamburger from 'assets/navbar-mobile.svg';
import MobileHamburgerClose from 'assets/mobile-hamburger-close.svg';
import LogoSvg from 'assets/logos/dataplaceai-color.svg';
import './MobileMenu.scss';
import { Link } from 'gatsby';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'utils/store/store';
import { useLocale } from 'locale/useLocale';
import LanguageSwitcher from 'utils/i18n/switcher/LanguageSwitcher';
import FooterGridBox from 'features/footer/footer-grid-box/FooterGridBox';
import Arrow from 'assets/arrowIcon.svg';

const MobileMenu: FC = () => {
  const [open, setOpen] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [openResource, setOpenResource] = React.useState(false);
  const { language } = useSelector((state: RootState) => state.i18n);
  const locale = useLocale('MobileMenu');

  const checkIfProductPage = () => {
    return (
      location?.pathname?.includes('placeme') ||
      location?.pathname?.includes('target') ||
      location?.pathname?.includes('source')
    );
  };

  return (
    <>
      <MobileHamburger
        className='mobile-hamburger'
        onClick={() => setOpen(!open)}
      />
      <div>
        <div className={open ? 'mobile-menu active' : 'mobile-menu'}>
          <div className='mobile-menu__top'>
            <Link to={'/' + language + '/'}>
              <LogoSvg className='mobile-menu__logo' />
            </Link>
            <MobileHamburgerClose
              className='mobile-hamburger-close'
              onClick={() => setOpen(!open)}
            />
          </div>

          <div className='mobile-menu__top--buttons'>
            <a href='https://app.dataplace.ai/auth/sign-in'>{locale?.logIn}</a>
            <a href='https://app.dataplace.ai/auth/sign-up'>{locale?.getStarted}</a>
          </div>

          <div className='mobile-menu__bottom'>
            <div>
              <a
                onClick={() => setOpenProducts(!openProducts)}
                className={`${checkIfProductPage() ? 'active' : ''}`}>
                {locale?.products}
                <Arrow className={`${openProducts ? '' : 'down'}`} />
              </a>

              <div className={`expanded-menu ${openProducts ? 'open' : ''}`}>
                <FooterGridBox isBig={false} />
              </div>
            </div>

            {(language === 'pl' || language === 'en-pl') && (
              <div>
                <Link
                  to={`/${language}/success-stories/`}
                  className={`${
                    location?.pathname?.includes('success-stories')
                      ? 'active'
                      : ''
                  }`}>
                  {language === 'pl' ? 'Historie klientów' : 'Success Stories'}
                </Link>
              </div>
            )}

            <div>
              <a
                onClick={() => setOpenResource(!openResource)}
                className={`${
                  location?.pathname?.includes('blog') ||
                  location?.pathname?.includes('report')
                    ? 'active'
                    : ''
                }`}>
                {locale?.resources}
                <Arrow className={`${openResource ? '' : 'down'}`} />
              </a>
              <div className={`expanded-menu ${openResource ? 'open' : ''}`}>
                <Link to={`/${language}/blog/`}>{locale?.blog}</Link>
                {language === 'pl' && (
                  <Link to={`/${language}/report/`}>{locale?.raports}</Link>
                )}
              </div>
            </div>

            <div>
              <Link
                to={`/${language}/contact/`}
                className={`${
                  location?.pathname?.includes('contact') ? 'active' : ''
                }`}>
                {locale?.contactPage}
              </Link>
            </div>

            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;

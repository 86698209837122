import React, { useEffect, useState } from 'react';
import Layout from 'containers/layout/Layout';
import Seo from 'utils/seo/Seo';
import Showcase from 'features/homepage/showcase/Showcase';
import Introduction from 'features/homepage/introduciton/introduction/Introduction';
import Cta from 'features/homepage/cta/Cta';
import CompanyInNumbers from './company-in-numbers/CompanyInNumbers';
import Awards from 'features/homepage/awards/Awards';
import OfflineAnalytics from 'features/homepage/offline-analytics/OfflineAnalytics';
import Technology from 'features/homepage/technology/Technology';
import Clients from 'features/clients/Clients';
import HubspotMeetingModal from 'ui/HubspotMeetingModal/HubspotMeetingModal';
import { navigate } from 'gatsby';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import { useShowcaseData } from 'features/homepage/showcase/showcaseService';
import Credentials from 'features/homepage/credentials/Credentials';
import BlogPost from 'features/homepage/blog-post/BlogPost';
import { Language, setLanguage } from 'utils/i18n/i18nSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { FreeConsultationPopup } from 'components/shared-components/popups/free-consultation/FreeConsultationPopup';
// import { checkForPopup } from 'components/shared-components/popups/generic-popup/checkForPopupService';
import { RootState } from 'utils/store/store';
// import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import EbookPopup from './popups/EbookPopup';
const linkResolver = require('../../../../linkResolver.js');

const IndexPage = (): JSX.Element => {
  const [showMeeting, setShowMeeting] = useState(false);
  const data = useShowcaseData();
  const dispatch = useDispatch();
  // const displayPopup = checkForPopup()
  //   ?.page?.text?.split(';')
  //   ?.filter(p => p !== '')
  //   ?.includes('homepage');
  const { language } = useSelector((state: RootState) => state.i18n);

  const { mobile } = useMediaQuery();

  const handleShowMeeting = () => {
    if (!showMeeting && !mobile) {
      setShowMeeting(true);
    } else {
      navigate(`/${language}/contact/`);
    }
  };

  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '');

      const findLangInUrl = (): number =>
        url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language;
      dispatch(setLanguage(urlLanguage));
    }
  }, []);

  // useEffect(() => {
  //   if (typeof window !== `undefined`) {
  //     if (
  //       sessionStorage.getItem('firstEnter') === null ||
  //       sessionStorage.getItem('firstEnter') === 'true'
  //     ) {
  //       setShowPopup(true);
  //     }
  //   }
  // }, []);

  return (
    <>
      {/* {showPopup && <FreeConsultationPopup show={showPopup} />} */}

      {language === 'pl' ? <EbookPopup /> : null}

      <Layout>
        <Seo
          title={data?.seo_title?.text}
          description={data?.seo_description?.text}
        />
        {showMeeting && (
          <HubspotMeetingModal
            closeMeeting={handleCloseMeeting}
            left='25vw'
            show={showMeeting}
            top='5vh'
          />
        )}
        <Showcase handleHubspot={handleShowMeeting} />
        <Clients />
        <Technology />
        <Introduction />
        <Cta handleHubspot={handleShowMeeting} />
        <CompanyInNumbers />
        {/* <Products handleHubspot={handleShowMeeting} /> */}
        <Credentials />
        <OfflineAnalytics />
        <Awards />
        <BlogPost />
      </Layout>
    </>
  );
};

export default withPrismicPreview(IndexPage, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);

import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { useSelector } from "react-redux";
import { Maybe, PrismicSuccessStories, PrismicSuccessStoriesDataType, PrismicSuccessStoriesEdge } from "types/declarations";
import { RootState } from "utils/store/store";
import {
  GetStoryPostDetails,
  GetSuccessStoriesHome,
  SuccessStory,
} from "./successStoriesModel";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";

const getHomeSuccessStories = ({
  allSuccessStories,
}: GetSuccessStoriesHome): Array<SuccessStory> => {
  const results = allSuccessStories;
  return results;
};

const getStoryPostDetails = ({
  allSuccessStories,
  link,
}: GetStoryPostDetails): Maybe<SuccessStory> => {
  if (allSuccessStories) {
    const index = allSuccessStories.findIndex(
      (post: SuccessStory) => post.link === link
    );
    if (index !== -1) {
      return allSuccessStories[index];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default {
  getHomeSuccessStories,
  getStoryPostDetails,
};

export const useSSData = (): PrismicSuccessStoriesDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicSuccessStories {
        edges {
          node {
            lang
            _previewable
            data {
              seo_description {
                text
              }
              seo_title {
                text
              }
              subtitle {
                text
              }
              title {
                text
              }
              image {
                alt
                url
              }
            }
          }
        }
      }
    }
  `);

  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicSuccessStories.edges
    .map((edge: PrismicSuccessStoriesEdge) => edge.node)
    .filter((node: PrismicSuccessStories) => node.lang === language)
    .map((node: PrismicSuccessStories) => node.data)[0];
};
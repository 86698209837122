import React from "react";
import styled, { css } from "styled-components";
import { useMediaQuery } from "utils/hooks/useMediaQuery";
import ArrowIcon from "assets/chevron-right.svg";

const CarouselButtonsWrapper = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()
  
  return css`
    display: flex;
    position: ${!mobile && 'absolute'} ;
    right: 0;
    top: ${tablet ? '2px' : '28px'};
  `
});
  
const CarouselButton = styled.button`
  background: transparent;
  border: none;
  font-size: 16;
  cursor: pointer;
`;

interface StyledArrowIconProp {
    reverse: boolean
  }

const StyledArrowIcon = styled(ArrowIcon)<StyledArrowIconProp>(({ reverse }) => css`
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
  ${reverse && css` transform: rotate(180deg);`}
`)

interface CarouselButtonsProps {
  handlePrev: () => void;
  handleNext: () => void;
}
  

const CarouselButtons = ({ handleNext, handlePrev }: CarouselButtonsProps) => (
  <CarouselButtonsWrapper>
    <CarouselButton onClick={handlePrev}>
      <StyledArrowIcon reverse />
    </CarouselButton>
    <CarouselButton onClick={handleNext}>
      <StyledArrowIcon />
    </CarouselButton>
  </CarouselButtonsWrapper>
)

export default CarouselButtons;

import { graphql, useStaticQuery } from 'gatsby'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { PrismicPricing, PrismicPricingDataType, PrismicPricingEdge } from '../types/Pricing'

export const usePricingData = (): PrismicPricingDataType => {
  const { language } = useSelector((state: RootState) => state.i18n)
  const result = useStaticQuery(graphql`
    {
      allPrismicPricing {
        edges {
          node {
            lang
            _previewable
            data {
              body {
                ... on PrismicPricingDataBodyBrandCarouselSlice {
                  id
                  slice_type
                  items {
                    client_image {
                      url
                    }
                  }
                }
                ... on PrismicPricingDataBodySuccessStories {
                  id
                  slice_type
                  items {
                    author {
                      richText
                    }
                    button_link {
                      raw
                    }
                    button_text {
                      richText
                    }
                    company_logo {
                      url
                      alt
                    }
                    description {
                      richText
                    }
                    number
                    number_description {
                      richText
                    }
                    percent
                    percent_description {
                      richText
                    }
                    second_number
                    second_number_description {
                      richText
                    }
                    subtitle {
                      richText
                    }
                    title {
                      richText
                    }
                  }
                },
                ... on PrismicPricingDataBodyPricingFormSlice {
                  id
                  slice_type
                  primary {
                    button_text {
                      richText
                    }
                    description {
                      richText
                      }
                    title {
                      richText
                    }
                    form_description {
                      richText
                    }
                    submit_button_text {
                      richText
                    }
                  }
                  items {
                    dropdown_element_description {
                      richText
                    }
                    dropdown_element_title {
                      richText
                    }
                  }
                }
                ... on PrismicPricingDataBodyFaqSlice {
                  id
                  slice_type
                  items {
                    dropdown_element_description {
                      richText
                    }
                    dropdown_element_title {
                      richText
                    }
                  }
                  primary {
                    title {
                      richText
                    }
                  }
                }
              }
              seo_title {
                text
              }
              seo_description {
                text
              }
            }
          }
        }
      }
    }
  `)
 
  const { data } = useMergePrismicPreviewData(result)

  return data.allPrismicPricing.edges
  .map((edge: PrismicPricingEdge) => edge.node)
    .filter((node: PrismicPricing) => node.lang === language)
      .map((node: PrismicPricing) => node.data)[0]
}

import React, {useEffect, useState} from "react";
import BlogLayout from "containers/blog-layout/BlogLayout";
import SearchHeader from "features/blog/search/SearchHeader";
import "./Blog.scss";
import Seo from "utils/seo/Seo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import HomeItems from "features/blog/home/HomeItems";
import SearchResults from "features/blog/search/results/SearchResults";
import { useBlogData } from "./blogService";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../linkResolver.js');

const BlogHome = (): JSX.Element => {
  const blog = useBlogData();
  const { searchQuery } = useSelector((state: RootState) => state.blog);
  const [isCleared, setIsCleared]=useState(false)
  const dispatch = useDispatch();
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('blog');
  
  const clearInput = (isCleared : boolean) => {
    setIsCleared(isCleared)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, [])

  return (
    <>
    <Seo title={blog?.seo_title?.text} description={blog?.seo_description?.text}/>

    {displayPopup && <GenericPopup /> }

    <BlogLayout>
      <section className="blog-container">
        <div className="blog-container__inner">
          <SearchHeader isCleared={isCleared} handleClear={clearInput}/>
          {!searchQuery && <HomeItems />}
          {searchQuery && <SearchResults handleClear={clearInput}/>}
          {/* <Pagination /> */}
        </div>
      </section>
    </BlogLayout>
    </>
  );
};

export default withPrismicPreview(BlogHome, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);
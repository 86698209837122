import * as React from "react";
import "./Navbar.scss";
import NavbarItem from "features/navbar/NavbarItem";
import MobileMenu from "../homepage/mobile-menu/MobileMenu";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Location from "images/placeme-location-contact.svg";
import Target from "images/placeme-target-contact.svg";
import Source from "images/placeme-source-contact.svg";
import LanguageSwitcherDropdown from "utils/i18n/switcher/LanguageSwitcherDropdown";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { useLocale } from "locale/useLocale";
import SharedButton from "ui/SharedButton/SharedButton";
import { productColors } from "shared/constants";

const Navbar = (): JSX.Element => {
  const location = useLocation();
  const { language } = useSelector((state: RootState) => state.i18n);
  const locale = useLocale("Navbar");

  return (
    <>
      <nav className="navbar">
        <ul>
          <NavbarItem
            showDropdownArrow
            active={
              location?.pathname?.includes("placeme") ||
              location?.pathname?.includes("target") ||
              location?.pathname?.includes("source") 
            }>
            {locale?.products}
            <div className="dropdown-content">
              <Link to={`/${language}/placeme/`}>
                <div
                  className="placeme-product-option"
                  style={{ backgroundColor: "#362fb7" }}>
                  <div className="placeme-product-option__img">
                    <img src={Location} alt="product logo" />
                  </div>
                  <div>placeme</div>
                </div>
              </Link>
              <Link to={`/${language}/target/`}>
                <div
                  className="placeme-product-option"
                  style={{ backgroundColor: "#cc3c00" }}>
                  <div className="placeme-product-option__img">
                    <img src={Target} alt="product logo" />
                  </div>
                  <div>target</div>
                </div>
              </Link>
              <Link to={`/${language}/source/`}>
                <div
                  className="placeme-product-option"
                  style={{ backgroundColor: "#6830B2" }}>
                  <div className="placeme-product-option__img">
                    <img src={Source} alt="product logo" />
                  </div>
                  <div>source</div>
                </div>
              </Link>
            </div>
          </NavbarItem>

          {(language === "pl" || language === "en-pl") && <NavbarItem active={location?.pathname?.includes("success-stories")}>
            <Link to={`/${language}/success-stories/`}>
              {language === "pl" ? 'Historie klientów' : 'Success Stories' }
            </Link>
          </NavbarItem>}
          
          <NavbarItem showDropdownArrow active={
            location?.pathname?.includes("blog") ||
            location?.pathname?.includes("report")
            }>
            {locale?.resources}
            <div className="dropdown-content">
              <Link to={`/${language}/blog/`}>{locale?.blog}</Link>
              {language === "pl" && (
                <Link to={`/${language}/report/`}>{locale?.industryReports}</Link>
              )}
            </div>
          </NavbarItem>

          <NavbarItem active={location?.pathname?.includes("contact")}>
            <Link to={`/${language}/contact/`}>{locale?.contact}</Link>
          </NavbarItem>

          <NavbarItem button>
            <Link to='https://app.dataplace.ai/auth/sign-in'>{locale?.logIn}</Link>
          </NavbarItem>
          {location?.pathname?.includes('placeme')
            ? (
              <SharedButton 
                buttonProps={{ $bgColor: productColors.violet.dark }}
                redirectsTo='https://app.dataplace.ai/auth/sign-up'
                >
                  {locale?.getStarted}
              </SharedButton>
            )
            : null
          }
          <LanguageSwitcherDropdown />
        </ul>
      </nav>
      <nav className="navbar-mobile">
        <MobileMenu />
      </nav>
    </>
  );
};

export default Navbar;

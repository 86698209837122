import React, { useMemo } from 'react'
import Seo from 'utils/seo/Seo'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Layout from 'containers/layout/Layout'
import { colors } from 'shared/constants'
import { usePlacemeFunctionalityData } from './hooks/useFunctionalitiesData'
import { renderSlice } from './utils/renderSlice'

const linkResolver = require('../../../../../linkResolver.js')

const PlacemeFunctionality = (): JSX.Element => {
  const functionalities = usePlacemeFunctionalityData()

  const renderedSlices = useMemo(() => {
    return functionalities?.body?.map(slice => renderSlice({ slice }))
  }, [functionalities])

  return (
    <>
      <Seo title={functionalities?.seo_title?.text} description={functionalities?.seo_description?.text}/>
      <Layout backgroundColor={colors.background}>
        {renderedSlices}
      </Layout>
    </>
  )
}

export default withPrismicPreview(PlacemeFunctionality, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
])

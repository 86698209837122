import React from "react";
import ReportsLayout from "containers/reports-layout/ReportsLayout";
import HomeItems from "features/reports/home/HomeItems";
import Seo from "utils/seo/Seo";
import './Reports.scss'
import { useReportPageData } from "./reportsService";
import ReportsShowcase from "./items/showcase/ReportsShowcase";
import FreeTrialBanner from "components/shared-components/free-trial-banner/FreeTrialBanner";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
const linkResolver = require('../../../../linkResolver.js');

const ReportsHome = (): JSX.Element => {
  const reports = useReportPageData();
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('reports');

  return (
    <>
      <Seo title={reports?.seo_title?.text} description={reports?.seo_description?.text}/>

      { displayPopup && <GenericPopup /> }

      <ReportsLayout>
        <section className="reports-container">
          <div className="reports-container__inner">
            <ReportsShowcase />
            <HomeItems />
          </div>
        </section>
        <FreeTrialBanner light />
      </ReportsLayout>
    </>
  );
};

export default withPrismicPreview(ReportsHome, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);